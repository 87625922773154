import { ShiftStateDependencies } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { Shift } from "@src/lib/interface/src";

export function mapShiftStateDependencies(shift: Shift): ShiftStateDependencies {
  const {
    _id,
    start,
    end,
    clockInOut,
    lunchInOut,
    facility,
    timecard,
    isInstantPay,
    deleted,
    verified,
    facilityId,
  } = shift;

  return {
    _id: _id ?? "",
    start: start ?? "",
    end: end ?? "",
    clockInOut,
    lunchInOut,
    facility: {
      id: facilityId ?? "",
      tmz: facility?.tmz ?? "",
    },
    timecard: {
      files: timecard?.files ?? [],
    },
    isInstantPay: isInstantPay ?? false,
    deleted: deleted ?? false,
    verified: verified ?? false,
  };
}
